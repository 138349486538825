import dayjs from "dayjs";
import { isEqual } from "lodash-es";
import { createSelector } from "reselect";

import {
  IPriceRange,
  ITrackingProperties,
  ListingSearchResult,
  Lodging,
  StayTypesEnum,
  VRAvailabilityResultEnum,
  ViewedPremierCollectionListProperties,
  ViewedVacationRentalListProperties,
  getNthNightTrackingProperty,
} from "redmond";

import { IStoreState } from "../../../../reducers/types";
import {
  getSelectedAccount,
  getSelectedAccountIndex,
} from "../../../rewards/reducer";
import {
  getAdultsCount,
  getChildrenCount,
  getLocationCategories,
  getLocationCategoriesLoading,
  getPetsCount,
  getPremierCollectionEntryProperties,
  getStayType,
  getVacationRentalEntryProperties,
  getVacationRentalsLocationCategories,
  getVacationRentalsLocationCategoriesLoading,
} from "../../../search/reducer";
import { SearchParams } from "../../../shop/utils/queryStringHelpers";
import {
  PremierCollectionAvailabilityCallState,
  PremierCollectionAvailabilitySortOption,
  initialFilterState,
  initialVacationRentalsFilterState,
} from "../index";
import {
  performAmenitiesFilter,
  performFreeCancellationFilter,
  performHotelNameFilter,
  performHotelsOnSaleFilter,
  performMaxPriceFilter,
  performStarRatingsFilter,
} from "../utils/processFilters";
import {
  getVRListingsSort,
  orderByPriceHighToLow,
  orderByPriceLowToHigh,
} from "../utils/processSort";

export const getPremierCollectionAvailabilityTrackingProperties = (
  state: IStoreState
) =>
  state.premierCollectionAvailability.availabilityResponse
    ?.trackingPropertiesV2;

export const getPremierCollectionAvailabilityLodgings = (state: IStoreState) =>
  state.premierCollectionAvailability.availabilityResponse?.lodgings;

export const getPremierCollectionAvailabilityNextPageToken = (
  state: IStoreState
) => state.premierCollectionAvailability.availabilityResponse?.nextPageToken;

export const getPremierCollectionAvailabilityCallState = (state: IStoreState) =>
  state.premierCollectionAvailability.PremierCollectionAvailabilityCallState;

export const getPremierCollectionAvailabilitySearchLocation = (
  state: IStoreState
) => state.premierCollectionAvailability.searchLocation;

export const getPremierCollectionAvailabilitypropertyIdInFocus = (
  state: IStoreState
) => state.premierCollectionAvailability.propertyIdInFocus;

export const getPremierCollectionAvailabilitypropertyIdHovered = (
  state: IStoreState
) => state.premierCollectionAvailability.propertyIdHovered;

export const getPremierCollectionAvailabilityAmenitiesFilter = (
  state: IStoreState
) => state.premierCollectionAvailability.amenities;

export const getPremierCollectionAvailabilityStarRatingsFilter = (
  state: IStoreState
) => state.premierCollectionAvailability.starRatings;

export const getPremierCollectionAvailabilityMaxPriceFilter = (
  state: IStoreState
) => state.premierCollectionAvailability.maxPrice;

export const getPremierCollectionAvailabilityCancellationFilter = (
  state: IStoreState
) => state.premierCollectionAvailability.freeCancellation;

export const getPremierCollectionAvailabilityHotelNameFilter = (
  state: IStoreState
) => state.premierCollectionAvailability.hotelName;

export const getPremierCollectionAvailabilityHotelsOnSaleFilter = (
  state: IStoreState
) => state.premierCollectionAvailability.hotelsOnSale;

export const getSelectedLodgingIndex = (state: IStoreState) =>
  state.premierCollectionAvailability.selectedLodgingIndex;

export const getIsFromHotelAvailability = (state: IStoreState) =>
  state.premierCollectionShop.isFromHotelAvailability;

export const getPremierCollectionBestOfferOverall = (state: IStoreState) =>
  state.premierCollectionAvailability.availabilityResponse?.bestOverallOffer;

export const getPremierCollectionAvailabilityMinMaxPriceRange = createSelector(
  getPremierCollectionAvailabilityLodgings,
  (lodgings): IPriceRange | null => {
    if (!lodgings || lodgings.length === 0) {
      return null;
    }

    let curMin = lodgings[0].price?.nightlyPrice.fiat.value;
    let curMax = lodgings[0].price?.nightlyPrice.fiat.value;

    lodgings.forEach((lodging) => {
      if (lodging.price) {
        const curPrice = lodging.price.nightlyPrice.fiat.value;

        if (!curMin || curPrice < curMin) curMin = curPrice;
        if (!curMax || curPrice > curMax) curMax = curPrice;
      }
    });

    // price happens to be optional, so there could be a case where all lodgings have no price
    if (curMin === undefined || curMax === undefined) return null;

    return {
      min: curMin,
      max: curMax,
    };
  }
);

export const getPremierCollectionHotelsNumberOfAppliedFilters = createSelector(
  getPremierCollectionAvailabilityMaxPriceFilter,
  getPremierCollectionAvailabilityAmenitiesFilter,
  getPremierCollectionAvailabilityStarRatingsFilter,
  getPremierCollectionAvailabilityHotelNameFilter,
  getPremierCollectionAvailabilityCancellationFilter,
  getPremierCollectionAvailabilityHotelsOnSaleFilter,
  (
    maxPrice,
    amenities,
    starRatings,
    hotelName,
    freeCancellation,
    hotelsOnSale
  ) => {
    return [
      maxPrice !== initialFilterState.maxPrice,
      amenities.length > 0,
      starRatings.length > 0,
      !!hotelName,
      freeCancellation,
      hotelsOnSale,
    ].filter((isSet) => isSet).length;
  }
);

export const getPremierCollectionAvailabilityCurrency = createSelector(
  getPremierCollectionAvailabilityLodgings,
  (lodgings): string => {
    if (lodgings) {
      const lodgingWithPrice = lodgings.find(
        (lodging) => !!lodging.price?.nightlyPrice
      );

      if (lodgingWithPrice?.price) {
        return lodgingWithPrice.price.nightlyPrice.fiat.currencyCode;
      }
    }

    return "USD";
  }
);

const getAllPremierCollectionAvailabilityFilters = createSelector(
  getPremierCollectionAvailabilityAmenitiesFilter,
  getPremierCollectionAvailabilityStarRatingsFilter,
  getPremierCollectionAvailabilityMaxPriceFilter,
  getPremierCollectionAvailabilityCancellationFilter,
  getPremierCollectionAvailabilityHotelNameFilter,
  getPremierCollectionAvailabilityHotelsOnSaleFilter,
  (
    amenities,
    starRatings,
    maxPrice,
    freeCancellation,
    hotelName,
    hotelsOnSale
  ) => {
    return {
      amenities,
      starRatings,
      maxPrice,
      freeCancellation,
      hotelName,
      hotelsOnSale,
    };
  }
);

export const getFilteredPremierCollectionAvailabilityLodgings = createSelector(
  getAllPremierCollectionAvailabilityFilters,
  getPremierCollectionAvailabilityLodgings,
  (
    {
      amenities,
      starRatings,
      maxPrice,
      freeCancellation,
      hotelName,
      hotelsOnSale,
    },
    lodgings
  ): Lodging[] => {
    return (lodgings ?? []).reduce((acc, curr) => {
      if (
        !acc.some((lodging) => lodging.lodging.id === curr.lodging.id) &&
        performAmenitiesFilter(curr, amenities) &&
        performStarRatingsFilter(curr, starRatings) &&
        performMaxPriceFilter(curr, maxPrice) &&
        performFreeCancellationFilter(curr, freeCancellation) &&
        performHotelNameFilter(curr, hotelName) &&
        performHotelsOnSaleFilter(curr, hotelsOnSale)
      ) {
        acc.push(curr);
      }
      return acc;
    }, [] as Lodging[]);
  }
);

export const getIsFilteredPremierCollectionAvailabilityLodgingsEmpty =
  createSelector(
    getFilteredPremierCollectionAvailabilityLodgings,
    getPremierCollectionAvailabilityCallState,
    (lodgings, availabilityCallState): boolean => {
      return (
        lodgings.length === 0 &&
        availabilityCallState ===
          PremierCollectionAvailabilityCallState.Complete
      );
    }
  );

export const getPremierCollectionAvailabilitySortOption = (
  state: IStoreState
) => state.premierCollectionAvailability.sortOption;

export const getFilteredAndSortedPremierCollectionAvailabilityLodgings =
  createSelector(
    getFilteredPremierCollectionAvailabilityLodgings,
    getPremierCollectionAvailabilitySortOption,
    (lodgings, sortOption): Lodging[] => {
      let filteredAndSortedLodgings = [...lodgings];

      switch (sortOption) {
        case PremierCollectionAvailabilitySortOption.PricingASC:
          filteredAndSortedLodgings = orderByPriceLowToHigh(
            filteredAndSortedLodgings
          );
          break;

        case PremierCollectionAvailabilitySortOption.PricingDESC:
          filteredAndSortedLodgings = orderByPriceHighToLow(
            filteredAndSortedLodgings
          );
          break;
      }

      return filteredAndSortedLodgings;
    }
  );

export const getMapBound = (state: IStoreState) =>
  state.premierCollectionAvailability.mapBound;

export const getOpenDatesModal = (state: IStoreState) =>
  state.premierCollectionAvailability.openDatesModal;

export const getPremierCollectionAvailabilitySearchLodgingIds = (
  state: IStoreState
) => state.premierCollectionAvailability.searchLodgingIds;

export const getPremierCollectionAvailabilityFromDate = (state: IStoreState) =>
  state.premierCollectionAvailability.searchFromDate;

export const getPremierCollectionAvailabilityUntilDate = (state: IStoreState) =>
  state.premierCollectionAvailability.searchUntilDate;

export const getSearchedNightCount = createSelector(
  getPremierCollectionAvailabilityFromDate,
  getPremierCollectionAvailabilityUntilDate,
  (fromDate, untilDate) => {
    if (!fromDate || !untilDate) {
      return null;
    }

    return dayjs(untilDate).diff(fromDate, "days");
  }
);

export const getPremierCollectionAvailabilitySearchLocationResult = (
  state: IStoreState
) => {
  return state.premierCollectionAvailability.searchLocationResult;
};

export const getUnavailableLodgingCount = createSelector(
  getPremierCollectionAvailabilityLodgings,
  (lodgings) =>
    lodgings?.filter((lodging) =>
      typeof lodging.available === "undefined" ? false : !lodging.available
    ).length || 0
);

export const getAvailableLodgingCount = createSelector(
  getPremierCollectionAvailabilityLodgings,
  (lodgings) =>
    lodgings?.filter((lodging) =>
      typeof lodging.available === "undefined" ? false : lodging.available
    ).length || 0
);

export const getNthNightMultipleLodgingsTrackingProperty = createSelector(
  getPremierCollectionAvailabilityLodgings,
  (lodgings) => {
    if (!lodgings) return undefined;

    let unlocked = 0;
    let locked = 0;

    for (const lodging of lodgings) {
      const trackingProperty = getNthNightTrackingProperty(lodging);
      if (trackingProperty === "unlocked") {
        unlocked++;
      }
      if (trackingProperty === "locked") {
        locked++;
      }
    }

    return {
      nth_night_unlocked_count: unlocked,
      nth_night_locked_count: locked,
    };
  }
);

const getAutocompleteState = (state: IStoreState) => {
  switch (getStayType(state)) {
    case StayTypesEnum.VacationRentals:
      return {
        categories: getVacationRentalsLocationCategories(state),
        loading: getVacationRentalsLocationCategoriesLoading(state),
      };
    case StayTypesEnum.Hotels:
      return {
        categories: getLocationCategories(state),
        loading: getLocationCategoriesLoading(state),
      };
  }
};

export const getMapSearchCategories = createSelector(
  getAutocompleteState,
  (state) => state.categories
);
export const getMapSearchCategoriesLoading = createSelector(
  getAutocompleteState,
  (state) => state.loading
);

export const getMapSearchQueryString = (state: IStoreState) =>
  state.premierCollectionAvailability.mapSearchQuery;

export const getMapSearchCategoriesFilteredBySearchString = createSelector(
  getMapSearchCategories,
  getMapSearchQueryString,
  (locationCategories, searchString) => {
    if (!searchString) {
      return locationCategories;
    }
    return locationCategories.map((locationCategory) => ({
      ...locationCategory,
      results: locationCategory.results.filter((result) =>
        result.label.toLowerCase().includes(searchString.toLowerCase())
      ),
    }));
  }
);

export const getPremierCollectionQueryParams = createSelector(
  getPremierCollectionAvailabilityFromDate,
  getPremierCollectionAvailabilityUntilDate,
  getAdultsCount,
  getChildrenCount,
  getPetsCount,
  getSelectedAccountIndex,
  getSelectedLodgingIndex,
  (
    fromDate,
    untilDate,
    adultsCount,
    childrenCount,
    petsCount,
    selectedAccountIndex,
    selectedLodgingIndex
  ) => ({
    fromDate,
    untilDate,
    adultsCount,
    childrenCount,
    petsCount,
    selectedAccountIndex,
    selectedLodgingIndex,
  })
);

export const getViewedPremierCollectionListProperties = createSelector(
  getPremierCollectionEntryProperties,
  getSelectedAccount,
  getPremierCollectionAvailabilityTrackingProperties,
  getUnavailableLodgingCount,
  getAvailableLodgingCount,
  getPremierCollectionBestOfferOverall,
  getNthNightMultipleLodgingsTrackingProperty,
  (
    properties,
    account,
    trackingProperties,
    unavailableLodgingCount,
    availableLodgingCount,
    bestOverallOffer,
    nthNightPromoTracking,
  ): ITrackingProperties<ViewedPremierCollectionListProperties> => {
    return {
      properties: {
        ...properties.properties,
        account_type_selected: account?.productDisplayName || "",
        unavailable_hotels: unavailableLodgingCount,
        available_hotels: availableLodgingCount,
        ...bestOverallOffer?.trackingPropertiesV2?.properties,
        has_offer: !!bestOverallOffer,
        account_use_type: account?.accountUseType,
        account_allow_rewards_redemption: account?.allowRewardsRedemption,
        ...trackingProperties?.properties,
        ...nthNightPromoTracking,
      },
      encryptedProperties: [
        bestOverallOffer?.trackingPropertiesV2?.encryptedProperties ?? "",
        trackingProperties?.encryptedProperties ?? "",
        ...(properties?.encryptedProperties || []),
      ],
    };
  }
);

export const getVacationRentalsAvailabilityNextPageToken = (
  state: IStoreState
) =>
  state.premierCollectionAvailability.vacationRentalsAvailabilityResponse
    ?.nextPageToken;

export const getVacationRentalsAvailabilityCallState = (state: IStoreState) =>
  state.premierCollectionAvailability.VacationRentalsAvailabilityCallState;

export const getVacationRentalsAvailabilityResponse = (state: IStoreState) =>
  state.premierCollectionAvailability.vacationRentalsAvailabilityResponse;

export const getVacationRentalAvailabilityListings = (state: IStoreState) =>
  state.premierCollectionAvailability.vacationRentalsAvailabilityResponse
    ?.listings;

export const getVacationRentalRoomCounts = (state: IStoreState) =>
  state.premierCollectionAvailability.vacationRentalsRoomCounts;

export const getVacationRentalAmenities = (state: IStoreState) =>
  state.premierCollectionAvailability.vacationRentalsAmenities;

export const getVacationRentalsNumberOfAppliedFilters = createSelector(
  getVacationRentalAmenities,
  getVacationRentalRoomCounts,
  (amenities, roomCounts) => {
    return [
      amenities.length > 0,
      !isEqual(
        roomCounts,
        initialVacationRentalsFilterState.vacationRentalsRoomCounts
      ),
    ].filter((isSet) => isSet).length;
  }
);

export const getSearchAdultsCount = (state: IStoreState) =>
  state.premierCollectionAvailability.searchAdultsCount;

export const getSearchChildrenCount = (state: IStoreState) =>
  state.premierCollectionAvailability.searchChildrenCount.length;

export const getFilteredSortedVacationRentalAvailabilityListings =
  createSelector(
    getVacationRentalAvailabilityListings,
    getVacationRentalRoomCounts,
    getVacationRentalAmenities,
    getPremierCollectionAvailabilitySortOption,
    (
      listings,
      roomCounts,
      amenities,
      sortOption
    ): ListingSearchResult[] | undefined => {
      const filteredListings = listings?.filter(
        (listing) =>
          listing.listing.terms.maxOccupancy >= roomCounts["min_guests"] &&
          listing.listing.content.layout.numberOfBedrooms >=
            roomCounts["min_bedrooms"] &&
          listing.listing.content.layout.numberOfBathrooms >=
            roomCounts["min_bathrooms"] &&
          listing.listing.content.layout.numberOfBeds >=
            roomCounts["min_beds"] &&
          (amenities.length === 0 ||
            listing.listing.content.amenities.some((amenity) =>
              amenities.some((filterAmenity) => amenity.kind === filterAmenity)
            ))
      );

      if (!filteredListings) return undefined;

      const availableListings = filteredListings.filter(
        (listing) =>
          listing.availability.AvailabilityResult ===
          VRAvailabilityResultEnum.Available
      );
      const unavailableListings = filteredListings.filter(
        (listing) =>
          listing.availability.AvailabilityResult !==
          VRAvailabilityResultEnum.Available
      );

      const sortedAvailableListings =
        getVRListingsSort(sortOption)(availableListings);
      const sortedUnavailableListings =
        getVRListingsSort(sortOption)(unavailableListings);

      const sortedListings = sortedAvailableListings.concat(
        sortedUnavailableListings
      );

      return sortedListings;
    }
  );

export const getVRAvailabilityTrackingProperties = (state: IStoreState) =>
  state.premierCollectionAvailability.vacationRentalsAvailabilityResponse
    ?.trackingProperties;

export const getViewedVacationRentalListProperties = createSelector(
  getVacationRentalEntryProperties,
  getSelectedAccount,
  getVRAvailabilityTrackingProperties,
  (
    properties,
    account,
    trackingProperties
  ): ITrackingProperties<ViewedVacationRentalListProperties> => {
    return {
      properties: {
        ...trackingProperties?.properties,
        ...properties.properties,
        account_type_selected: account?.productDisplayName || "",
        account_use_type: account?.accountUseType,
        account_allow_rewards_redemption: account?.allowRewardsRedemption,
      },
      encryptedProperties: [],
    };
  }
);

export const getCurrentSearchParams = createSelector(
  getPremierCollectionAvailabilitySearchLocationResult,
  getStayType,
  getPremierCollectionAvailabilityFromDate,
  getPremierCollectionAvailabilityUntilDate,
  getSearchAdultsCount,
  getSearchChildrenCount,
  (state: IStoreState) => state.premierCollectionAvailability.searchPetsCount,
  (
    location,
    stayType,
    fromDate,
    untilDate,
    adultsCount,
    childrenCount,
    petsCount
  ): SearchParams => {
    switch (stayType) {
      case StayTypesEnum.VacationRentals:
        return {
          type: StayTypesEnum.VacationRentals,
          location,
          fromDate,
          untilDate,
          adultsCount,
          childrenCount,
          petsCount,
        };
      case StayTypesEnum.Hotels:
        return {
          type: StayTypesEnum.Hotels,
          location,
          fromDate,
          untilDate,
          adultsCount,
          childrenCount,
        };
    }
  }
);
