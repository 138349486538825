import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import React from "react";
import { RouterProps } from "react-router";
import { Link } from "react-router-dom";

import {
  ActionButton,
  B2BLoadingPopup,
  BackButton,
  BackToTopButton,
  Header,
  HotelSplitMapView,
  Icon,
  IconName,
  LifestyleCollectionSearchLoadingImage,
  PremierCollectionBenefits,
  PremierCollectionSearchLoadingImage,
  PremiumStaysSearchLoadingImage,
  useDeviceTypes,
} from "halifax";
import {
  BaseAddress,
  IIdLodgings,
  ListingSearchResult,
  Lodging,
  LodgingCollectionEnum,
  SELECTED_PC_FROM_LIST,
  SELECTED_PC_FROM_MAP,
  SELECTED_VR_FROM_LIST,
  SELECTED_VR_FROM_MAP,
  StayTypesEnum,
  VIEWED_PC_LIST,
  VRAvailabilityResultEnum,
  getNthNightTrackingProperty,
} from "redmond";

import { ClientContext } from "../../App";
import { trackEvent } from "../../api/v0/analytics/trackEvent";
import {
  AVAILABLE,
  CONTROL,
  GLOBAL_MOBILE_NAV_EXPERIMENT,
  LC_FOR_NON_PREMIUM_CARDHOLDERS_EXPERIMENT,
  LC_FOR_NON_PREMIUM_CARDHOLDERS_VARIANTS,
  LC_FOR_PREMIUM_CARDHOLDERS_EXPERIMENT,
  LC_FOR_PREMIUM_CARDHOLDERS_VARIANTS,
  TRAVEL_CREDIT_HISTORY_EXPERIMENT,
  TRAVEL_WALLET_CREDITS_EXPERIMENT,
  VR_FOR_NON_PREMIUM_CARDHOLDERS_EXPERIMENT,
  VR_FOR_PREMIUM_CARDHOLDERS_EXPERIMENT,
  getExperimentVariant,
  getExperimentVariantCustomVariants,
  useExperiments,
} from "../../context/experiments";
import {
  PATH_AVAILABILITY,
  PATH_HOME,
  PATH_SHOP,
  PATH_VACATION_RENTALS_AVAILABILITY,
  PATH_VACATION_RENTALS_SHOP,
} from "../../utils/paths";
import { RewardsAccountSelection } from "../rewards/components";
import {
  transformToStringifiedAvailabilityQuery,
  transformToStringifiedQuery,
} from "../shop/utils/queryStringHelpers";
import { TravelWalletDrawer } from "../travel-wallet/components";
import { transformToStringifiedVRQuery } from "../vacation-rental-shop/utils/queryStringHelpers";
import { AllFiltersModal } from "./components/AvailabilityFilter/components/AllFiltersModal";
import { PremierCollectionAvailabilityList } from "./components/AvailabilityList";
import { AvailabilityMap } from "./components/AvailabilityMap";
import { AvailabilityMapHotelTooltip } from "./components/AvailabilityMap/components/AvailabilityMapHotelTooltip";
import { MobileAvailabilitySearchControl } from "./components/MobileAvailabilitySearchControl";
import { PremierCollectionAvailabilityConnectorProps } from "./container";
import * as constants from "./textConstants";

import "./styles.scss";

export interface IPremierCollectionAvailability
  extends PremierCollectionAvailabilityConnectorProps,
    RouterProps {}

export const PremierCollectionAvailability = (
  props: IPremierCollectionAvailability
) => {
  const {
    // Context
    history,
    fromDate,
    untilDate,
    stayType,
    setStayType,
    isMapSearch,
    searchLocation,
    hotelQueryParams,
    searchParams,
    hotelsFiltersCount,
    vrFiltersCount,
    // Property selection
    setSelectedListingId,
    selectHome,
    setSelectedLodgingIndex,
    setPropertyIdInFocus,
    focusedProperty,
    // Availability management
    isFetchingInitialAvailability,
    isStillFetchingInFollowUpCalls,
    fetchInitialVacationRentalsAvailability,
    fetchInitialPremierCollectionAvailability,
    // Autocomplete
    vacationRentalsLocationCategories,
    fetchVacationRentalsLocationCategories,
    fetchLifestyleLocationCategories,
    fetchLocationCategories,
    // Accounts
    largestValueAccount,
    fetchTravelWalletDetails,
    fetchTravelWalletCreditHistory,
    // Tracking
    viewedPCListProperties,
    trackingProps,
    viewedPremierCollectionListProperties,
    viewedVacationRentalListProperties,
    listPaymentMethods,
  } = props;

  const clientContext = React.useContext(ClientContext);
  const { matchesMobile } = useDeviceTypes();
  const [currentUrl, setCurrentUrl] = React.useState(history.location.search);
  const [showExpandedMap, setShowExpandedMap] = React.useState(false);
  const [isFiltersModalOpen, setOpenFiltersModal] =
    React.useState<boolean>(false);
  const locationName = React.useMemo(() => {
    const label = isMapSearch ? "Map area" : searchLocation?.label ?? "";
    return label.split(",")[0];
  }, [searchLocation, isMapSearch]);

  const expState = useExperiments();

  const travelWalletCreditsExperiment = getExperimentVariant(
    expState.experiments,
    TRAVEL_WALLET_CREDITS_EXPERIMENT
  );
  const isTravelWalletCreditsExperiment = React.useMemo(
    () => travelWalletCreditsExperiment === AVAILABLE,
    [travelWalletCreditsExperiment]
  );

  const travelCreditHistoryExperiment = getExperimentVariant(
    expState.experiments,
    TRAVEL_CREDIT_HISTORY_EXPERIMENT
  );
  const isTravelCreditHistoryExperiment = React.useMemo(
    () => travelCreditHistoryExperiment === AVAILABLE,
    [travelCreditHistoryExperiment]
  );

  const LCForPremiumCardholderVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    LC_FOR_PREMIUM_CARDHOLDERS_EXPERIMENT,
    LC_FOR_PREMIUM_CARDHOLDERS_VARIANTS
  );

  const isLCForPremiumCardHoldersEnabled =
    LCForPremiumCardholderVariant !== CONTROL;

  const LCForNonPremiumCardholderVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    LC_FOR_NON_PREMIUM_CARDHOLDERS_EXPERIMENT,
    LC_FOR_NON_PREMIUM_CARDHOLDERS_VARIANTS
  );

  const isLCForNonPremiumCardHoldersEnabled =
    LCForNonPremiumCardholderVariant !== CONTROL;

  const VRForPremiumCardHolders = getExperimentVariant(
    expState.experiments,
    VR_FOR_PREMIUM_CARDHOLDERS_EXPERIMENT
  );
  const isVRForPremiumCardHoldersEnabled = React.useMemo(
    () => VRForPremiumCardHolders === AVAILABLE,
    [VRForPremiumCardHolders]
  );

  const VRForNonPremiumCardHolders = getExperimentVariant(
    expState.experiments,
    VR_FOR_NON_PREMIUM_CARDHOLDERS_EXPERIMENT
  );
  const isVRForNonPremiumCardHoldersEnabled = React.useMemo(
    () => VRForNonPremiumCardHolders === AVAILABLE,
    [VRForPremiumCardHolders]
  );

  const isPremiumVR =
    isVRForPremiumCardHoldersEnabled &&
    stayType === StayTypesEnum.VacationRentals;

  const isNonPremiumVR =
    isVRForNonPremiumCardHoldersEnabled &&
    stayType === StayTypesEnum.VacationRentals;

  const isVR = isPremiumVR || isNonPremiumVR;

  const globalMobileNavExperimentVariant = getExperimentVariant(
    expState.experiments,
    GLOBAL_MOBILE_NAV_EXPERIMENT
  );
  const isGlobalMobileNavExperiment = React.useMemo(
    () => globalMobileNavExperimentVariant === AVAILABLE,
    [globalMobileNavExperimentVariant]
  );

  React.useEffect(() => {
    if (stayType === StayTypesEnum.Hotels) {
      if (trackingProps) {
        trackEvent({
          eventName: VIEWED_PC_LIST,
          ...viewedPCListProperties,
        });
      }
    }
  }, [trackingProps, viewedPCListProperties, stayType]);

  React.useEffect(() => {
    fetchTravelWalletDetails();
    listPaymentMethods();
    isLCForNonPremiumCardHoldersEnabled
      ? fetchLifestyleLocationCategories()
      : fetchLocationCategories();
    // TODO: Find out why window.scrollTo requires a setTimeout; it's needed so that the browser nav button works with scrollTo
    // document.title = SELECT_HOTEL_TITLE;
    // setTimeout(() => window.scrollTo(0, 0), 0);
    // return () => {
    //   document.title = PORTAL_TITLE;
    // };
  }, []);

  const doSearch = ({
    isVRSearch = isVR,
    updateQuery = false,
  }: {
    isVRSearch?: boolean;
    updateQuery?: boolean;
  }) => {
    if (isVRSearch) {
      if (updateQuery) {
        history.push(
          `${PATH_VACATION_RENTALS_AVAILABILITY}${transformToStringifiedAvailabilityQuery(
            {
              location: (searchParams.location?.id as IIdLodgings)
                ?.lodgingSelection.searchTerm,
              fromDate: searchParams.fromDate,
              untilDate: searchParams.untilDate,
              adultsCount: searchParams.adultsCount,
              childrenCount: searchParams.childrenCount,
              petsCount:
                searchParams.type === StayTypesEnum.VacationRentals
                  ? searchParams.petsCount
                  : 0,
            }
          )}`
        );
      }
      setStayType(StayTypesEnum.VacationRentals);
      fetchInitialVacationRentalsAvailability(history, {
        includePremierCollection: isVRForPremiumCardHoldersEnabled,
      });
    } else {
      if (updateQuery) {
        history.push(
          `${PATH_AVAILABILITY}${transformToStringifiedAvailabilityQuery({
            location: (searchParams.location?.id as IIdLodgings)
              ?.lodgingSelection.searchTerm,
            fromDate: searchParams.fromDate,
            untilDate: searchParams.untilDate,
            adultsCount: searchParams.adultsCount,
            childrenCount: searchParams.childrenCount,
          })}`
        );
      }
      setStayType(StayTypesEnum.Hotels);
      fetchInitialPremierCollectionAvailability(
        history,
        isLCForNonPremiumCardHoldersEnabled
          ? LodgingCollectionEnum.Lifestyle
          : LodgingCollectionEnum.Premier
      );
    }
  };

  React.useEffect(() => {
    if (expState.experiments.length > 0) {
      doSearch({
        isVRSearch:
          history.location.pathname === PATH_VACATION_RENTALS_AVAILABILITY &&
          (isVRForPremiumCardHoldersEnabled ||
            isVRForNonPremiumCardHoldersEnabled),
      });
    }
  }, [history.location.pathname, isVRForPremiumCardHoldersEnabled, expState]);

  React.useEffect(() => {
    if (history.location.search !== currentUrl) {
      doSearch({});
      setCurrentUrl(history.location.search);
    }
  }, [history.location.search, currentUrl, isVR]);

  React.useEffect(() => {
    if (isTravelCreditHistoryExperiment) {
      fetchTravelWalletCreditHistory();
    }
  }, [isTravelCreditHistoryExperiment]);

  React.useEffect(() => {
    if (
      vacationRentalsLocationCategories.length === 0 &&
      stayType === StayTypesEnum.VacationRentals &&
      isVRForPremiumCardHoldersEnabled
    ) {
      fetchVacationRentalsLocationCategories();
    }
  }, [stayType]);

  const outerFiltersCount = React.useMemo(() => {
    switch (stayType) {
      case StayTypesEnum.Hotels:
        return hotelsFiltersCount;
      case StayTypesEnum.VacationRentals:
        return vrFiltersCount;
    }
  }, [hotelsFiltersCount, vrFiltersCount]);

  const navigateToProperty = ({
    property,
    index,
    source,
  }: {
    property: Lodging | ListingSearchResult;
    index: number;
    source: "list" | "map";
  }) => {
    if ("lodging" in property) {
      trackEvent({
        eventName:
          source === "map" ? SELECTED_PC_FROM_MAP : SELECTED_PC_FROM_LIST,
        properties: {
          ...viewedPremierCollectionListProperties.properties,
          ...property.trackingPropertiesV2?.properties,
          is_preferred_cot: property.isPreferred,
          lodging_row_index: index,
          nth_night_promo: getNthNightTrackingProperty(property),
        },
        encryptedProperties: [
          ...viewedPremierCollectionListProperties.encryptedProperties,
          property.trackingPropertiesV2?.encryptedProperties ?? "",
        ],
      });
      setSelectedLodgingIndex(index);
      if (property.available) {
        const params = transformToStringifiedQuery({
          lodgingId: property.lodging.id,
          lodgingSelection: searchLocation?.label || "",
          ...hotelQueryParams,
          selectedLodgingIndex: index,
        });
        if (matchesMobile)
          history.push(`${PATH_SHOP}${params}`, {
            fromPage: history.location.pathname,
          });
        else window.open(`${PATH_SHOP}${params}`, "_blank");
      }
    } else {
      const listingId = property.listingId;
      trackEvent({
        eventName:
          source === "map" ? SELECTED_VR_FROM_MAP : SELECTED_VR_FROM_LIST,
        properties: {
          ...viewedVacationRentalListProperties.properties,
          vr_type: property.listing.listingCollection,
          vr_occupancy: property.listing.terms.maxOccupancy,
          vr_pet_friendly: property.listing.terms.petFriendly,
          vr_market: (
            property?.listing?.content?.location.address as BaseAddress
          )?.city,
          vr_price: property.availability.rate?.price.total.fiat,
        },
        encryptedProperties: [
          ...viewedVacationRentalListProperties.encryptedProperties,
        ],
      });
      setSelectedListingId(listingId);
      selectHome(property);
      if (
        property.availability.AvailabilityResult ===
        VRAvailabilityResultEnum.Available
      ) {
        const params = transformToStringifiedVRQuery({
          listingId: listingId.id,
          location: searchLocation?.label || "",
          ...hotelQueryParams,
        });
        if (matchesMobile)
          history.push(`${PATH_VACATION_RENTALS_SHOP}${params}`);
        else window.open(`${PATH_VACATION_RENTALS_SHOP}${params}`, "_blank");
      }
    }
  };

  const renderDesktopView = () => {
    const headerLocationName = isMapSearch ? "map area" : locationName;
    return (
      <>
        <HotelSplitMapView
          className="hotel-availability-container"
          header={
            <div>
              <Header
                className="rewards-components-section"
                left={
                  <Box className={"rewards-account-section-left-content"}>
                    <Link className="logo" to={PATH_HOME}>
                      {clientContext.logo}
                    </Link>
                    <Box className={"rewards-account-section-travel-details"}>
                      <Typography variant={"body1"}>
                        {isLCForPremiumCardHoldersEnabled
                          ? constants.VIEWING_PREMIUM_STAYS_TEXT(
                              headerLocationName
                            )
                          : constants.VIEWING_HOTELS_TEXT(headerLocationName)}
                      </Typography>
                      {fromDate && untilDate ? (
                        <Typography variant={"body2"}>
                          {constants.DATES_TEXT(
                            dayjs(fromDate).format("ddd, MMM DD"),
                            dayjs(untilDate).format("ddd, MMM DD")
                          )}
                        </Typography>
                      ) : null}
                    </Box>
                  </Box>
                }
                right={
                  <Box className="desktop-hotel-availability-rewards-account-contents">
                    <RewardsAccountSelection
                      className={clsx("b2b", {
                        "hide-balance-border": isTravelWalletCreditsExperiment,
                      })}
                      popoverClassName="b2b"
                    />
                    {isTravelWalletCreditsExperiment ? (
                      <TravelWalletDrawer />
                    ) : null}
                  </Box>
                }
              />
              <PremierCollectionBenefits
                variant={(() => {
                  if (isLCForPremiumCardHoldersEnabled)
                    return "availability-with-lifestyle-collection";
                  if (isLCForNonPremiumCardHoldersEnabled)
                    return "availability-only-lifestyle-collection";
                  return "availability";
                })()}
                largestValueAccount={largestValueAccount}
                includesVacationRentals={
                  isVRForNonPremiumCardHoldersEnabled ||
                  isVRForNonPremiumCardHoldersEnabled
                }
                stayType={stayType}
              />
            </div>
          }
          leftClassName="list-section"
          left={
            <>
              <PremierCollectionAvailabilityList
                isVRForPremiumCardHoldersEnabled={
                  isVRForPremiumCardHoldersEnabled
                }
                fetchInitial={() => doSearch({ updateQuery: true })}
                isLCForPremiumCardHoldersEnabled={
                  isLCForPremiumCardHoldersEnabled
                }
                isLCForNonPremiumCardHoldersEnabled={
                  isLCForNonPremiumCardHoldersEnabled
                }
                navigateToProperty={(property, index) => {
                  navigateToProperty({ property, index, source: "list" });
                }}
              />
            </>
          }
          rightClassName="map-section"
          right={
            <AvailabilityMap
              isVRForPremiumCardHoldersEnabled={
                isVRForPremiumCardHoldersEnabled
              }
              isLCForPremiumCardHoldersEnabled={
                isLCForPremiumCardHoldersEnabled
              }
              isLCForNonPremiumCardHoldersEnabled={
                isLCForNonPremiumCardHoldersEnabled
              }
              navigateToProperty={(property, index) =>
                navigateToProperty({ property, index, source: "map" })
              }
            />
          }
        />
      </>
    );
  };

  const renderMobileView = () => {
    return (
      <Box className="hotel-availability-container">
        <Box className="mobile-pc-availability-page">
          {showExpandedMap ? (
            <Box className="mobile-pc-availability-map-wrapper">
              <Box
                className={clsx(
                  "mobile-pc-availability-map-container",
                  "full-height"
                )}
              >
                <AvailabilityMap
                  isVRForPremiumCardHoldersEnabled={
                    isVRForPremiumCardHoldersEnabled
                  }
                  isLCForPremiumCardHoldersEnabled={
                    isLCForPremiumCardHoldersEnabled
                  }
                  isLCForNonPremiumCardHoldersEnabled={
                    isLCForNonPremiumCardHoldersEnabled
                  }
                  navigateToProperty={(property, index) =>
                    navigateToProperty({ property, index, source: "map" })
                  }
                />
                <Box className="map-back-button-container">
                  <BackButton onClick={() => setShowExpandedMap(false)} />
                </Box>
                <Box className="map-filters-button-container">
                  <AllFiltersModal
                    isMobile
                    open={isFiltersModalOpen}
                    setOpen={setOpenFiltersModal}
                  />
                  <ActionButton
                    className="premium-stays-all-filters-modal-button"
                    defaultStyle="h4r-secondary"
                    message={`All Filters${
                      outerFiltersCount > 0 ? ` (${outerFiltersCount})` : ""
                    }`}
                    icon={<Icon name={IconName.Settings} />}
                    onClick={() => setOpenFiltersModal(true)}
                  />
                </Box>
              </Box>
              {focusedProperty != null && (
                <Box className="mobile-lodging-availability-map-bottom-items">
                  <Box className="mobile-lodging-availability-map-rolling-gallery">
                    <AvailabilityMapHotelTooltip
                      lodging={focusedProperty.property}
                      onClickHotelDetails={(e) => {
                        e.stopPropagation();
                        setPropertyIdInFocus(focusedProperty.id);
                        navigateToProperty({
                          property: focusedProperty.property,
                          index: focusedProperty.index,
                          source: "map",
                        });
                      }}
                      onCloseClick={(e) => {
                        e.stopPropagation();
                        setPropertyIdInFocus(null);
                      }}
                    />
                  </Box>
                </Box>
              )}
            </Box>
          ) : (
            <>
              <MobileAvailabilitySearchControl
                isLifestyleCollection={isLCForNonPremiumCardHoldersEnabled}
                includesLifestyleCollection={isLCForPremiumCardHoldersEnabled}
              />
              <Box className="mobile-rewards-account-contents">
                <RewardsAccountSelection
                  className="b2b"
                  popoverClassName="b2b"
                />
              </Box>

              {!isLCForPremiumCardHoldersEnabled &&
                stayType !== StayTypesEnum.VacationRentals && (
                  <PremierCollectionBenefits
                    variant={(() => {
                      if (isLCForNonPremiumCardHoldersEnabled)
                        return "availability-only-lifestyle-collection";
                      return "availability";
                    })()}
                    isMobile
                    largestValueAccount={largestValueAccount}
                  />
                )}
              <Box className={clsx("list-section", { mobile: matchesMobile })}>
                <PremierCollectionAvailabilityList
                  fetchInitial={() => {
                    doSearch({});
                  }}
                  openExpandedMap={() => setShowExpandedMap(true)}
                  navigateToProperty={(property, index) =>
                    navigateToProperty({ property, index, source: "list" })
                  }
                  isVRForPremiumCardHoldersEnabled={
                    isVRForPremiumCardHoldersEnabled
                  }
                  isLCForPremiumCardHoldersEnabled={
                    isLCForPremiumCardHoldersEnabled
                  }
                  isLCForNonPremiumCardHoldersEnabled={
                    isLCForNonPremiumCardHoldersEnabled
                  }
                  isMobile
                />
              </Box>
              {isGlobalMobileNavExperiment ? <BackToTopButton /> : undefined}
              {!isGlobalMobileNavExperiment &&
              isTravelWalletCreditsExperiment ? (
                <TravelWalletDrawer />
              ) : undefined}
            </>
          )}
        </Box>
      </Box>
    );
  };

  return (
    <>
      {isFetchingInitialAvailability || isStillFetchingInFollowUpCalls ? (
        <B2BLoadingPopup
          open={isFetchingInitialAvailability || isStillFetchingInFollowUpCalls}
          message={
            isLCForPremiumCardHoldersEnabled ||
            isLCForNonPremiumCardHoldersEnabled
              ? constants.FETCHING_LC_AVAILABILITY_TEXT
              : constants.FETCHING_AVAILABILITY_TEXT
          }
          secondaryMessage={(() => {
            if (isVR) {
              return constants.FETCHING_VR_AVAILABILITY_SECONDARY_TEXT(
                isPremiumVR
              );
            }

            if (
              isLCForPremiumCardHoldersEnabled ||
              isLCForNonPremiumCardHoldersEnabled
            ) {
              return constants.FETCHING_LC_AVAILABILITY_SECONDARY_TEXT(
                isLCForPremiumCardHoldersEnabled
              );
            }

            return constants.FETCHING_AVAILABILITY_SECONDARY_TEXT;
          })()}
          image={(() => {
            if (isPremiumVR || isLCForPremiumCardHoldersEnabled) {
              return PremiumStaysSearchLoadingImage;
            }
            if (isLCForNonPremiumCardHoldersEnabled) {
              return LifestyleCollectionSearchLoadingImage;
            }

            return PremierCollectionSearchLoadingImage;
          })()}
          className={clsx("premier-collection-search-loading-popup", {
            "with-lifestyle-collection": isLCForPremiumCardHoldersEnabled,
            "lifestyle-collection": isLCForNonPremiumCardHoldersEnabled,
          })}
          popupSize={matchesMobile ? "mobile" : "desktop"}
        />
      ) : null}
      <Box
        className={clsx("pc-hotel-availability-root", {
          "full-width": !matchesMobile,
          mobile: matchesMobile,
          "with-lifestyle-collection": isLCForPremiumCardHoldersEnabled,
          "only-lifestyle-collection": isLCForNonPremiumCardHoldersEnabled,
          "global-mobile-nav": isGlobalMobileNavExperiment,
        })}
      >
        {!matchesMobile ? renderDesktopView() : renderMobileView()}
      </Box>
    </>
  );
};
