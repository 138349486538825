import React, { useEffect, useMemo, useState } from "react";
import {
  getIsFreeBreakfast,
  HotelInformationalBanner,
  HotelShopChooseRoom,
  Icon,
  IconName,
  textConstants as halifaxTextConstants,
  VoidWindowNotice,
} from "halifax";
import { RouteComponentProps } from "react-router-dom";
import { DesktopPremierCollectionShopChooseRoomConnectorProps } from "./container";
import { CalendarPickerButton } from "../../../search/components/PremierCollectionSearchControl/components/CalendarPickerButton";
import { Box, IconButton, Tooltip, Typography } from "@material-ui/core";
import "./styles.scss";
import * as textConstants from "./textConstants";
import clsx from "clsx";
import { TravelWalletDetailsBanner } from "../../../travel-wallet/components";
import {
  CancellationReason,
  CorpRoomInfoProducts,
  CustomerAccountRole,
  SelectedTravelOfferScreen,
} from "redmond";
import {
  AVAILABLE,
  getExperimentVariant,
  HOTELS_CALIFORNIA_BILL_644_EXPERIMENT,
  TRAVEL_WALLET_OFFER_EXPERIMENT,
  useExperiments,
} from "../../../../context/experiments";
import { config } from "../../../../api/config";
import { getRoomPolicyComplianceType } from "@capone/common/src/utils/hotelShopPolicyCompliance";

export interface DesktopPremierCollectionShopChooseRoomProps
  extends DesktopPremierCollectionShopChooseRoomConnectorProps,
    RouteComponentProps {
  variant?: "default" | "lifestyle-collection";
}

export const DesktopPremierCollectionShopChooseRoom = (
  props: DesktopPremierCollectionShopChooseRoomProps
) => {
  const {
    variant = "default",
    largestEarnAccount,
    selectedLodging,
    showOfferBasedOnSelectedLodging,
    shopCancelationSummary,
  } = props;
  const expState = useExperiments();
  const [showOfferBanner, setShowOfferBanner] = useState(false);

  const travelWalletOffer = getExperimentVariant(
    expState.experiments,
    TRAVEL_WALLET_OFFER_EXPERIMENT
  );
  const isTravelWalletOfferExperiment = useMemo(
    () => travelWalletOffer === AVAILABLE,
    [travelWalletOffer]
  );

  const californiaBill644Variant = getExperimentVariant(
    expState.experiments,
    HOTELS_CALIFORNIA_BILL_644_EXPERIMENT
  );
  const isCaliforniaBill644Experiment = useMemo(() => {
    return californiaBill644Variant === AVAILABLE;
  }, [californiaBill644Variant]);

  useEffect(() => {
    setShowOfferBanner(
      isTravelWalletOfferExperiment && showOfferBasedOnSelectedLodging
    );
  }, [showOfferBasedOnSelectedLodging]);

  const showFree24HourCancel =
    isCaliforniaBill644Experiment &&
    shopCancelationSummary?.reasons.includes(
      CancellationReason.CaliforniaBill644
    );

  return (
    <Box
      className={clsx("pc-shop-choose-room-button-and-benefits-wrapper", {
        "lifestyle-collection": variant === "lifestyle-collection",
        "free-breakfast":
          variant === "lifestyle-collection" &&
          selectedLodging?.lodging &&
          getIsFreeBreakfast(selectedLodging?.lodging.amenities, true) &&
          !selectedLodging?.nthNightPromotion,
        "nth-night": selectedLodging?.nthNightPromotion,
      })}
    >
      {variant === "lifestyle-collection" ? (
        <Icon name={IconName.LifestyleCollectionRibbon} />
      ) : (
        <Icon name={IconName.PremierCollectionRibbon} />
      )}
      {variant === "lifestyle-collection" &&
      selectedLodging?.lodging &&
      getIsFreeBreakfast(selectedLodging?.lodging.amenities, true) &&
      !selectedLodging.nthNightPromotion ? (
        <HotelInformationalBanner
          content={
            <Tooltip
              title={
                <Typography className="tooltip-text">
                  {halifaxTextConstants.FREE_BREAKFAST_TOOLTIP}
                </Typography>
              }
              classes={{
                popper: "free-breakfast-tooltip-popper",
                tooltip: "free-breakfast-tooltip-text",
              }}
            >
              <Box className="free-breakfast-banner-content">
                <Typography className="free-breakfast-content-text">
                  Free breakfast for cardholders
                </Typography>
                <Icon name={IconName.InfoCircle} />
              </Box>
            </Tooltip>
          }
          isMobile={false}
          isMap={false}
          iconName={IconName.FreeBreakfast}
        />
      ) : null}
      <HotelShopChooseRoom
        {...props}
        datepicker={
          <CalendarPickerButton
            classes={["room-calendar-button"]}
            hideSeparator
          />
        }
        onClickContinue={() =>
          document
            .getElementsByClassName("reserve-cta")[0]
            ?.scrollIntoView({ behavior: "smooth", block: "end" })
        }
        buttonClassName="b2b"
        tenant={config.TENANT}
        policyComplianceType={getRoomPolicyComplianceType(
          props.roomInfoProducts as CorpRoomInfoProducts[]
        )}
      />
      {largestEarnAccount && largestEarnAccount.earn.hotelsMultiplier && (
        <Box className={clsx("pc-shop-benefits", {
          "nth-night": selectedLodging?.nthNightPromotion,
        })}>
          <Box className="pc-benefits-title">
            <Icon name={IconName.StarOutline} />
            <Typography className="pc-benefits-title-text">
              {variant === "lifestyle-collection"
                ? textConstants.LC_BENEFITS_TITLE
                : textConstants.BENEFITS_TITLE}
            </Typography>
          </Box>

          <Typography className="pc-benefits-list">
            {textConstants.getBenefitsList(
              largestEarnAccount.earn.hotelsMultiplier,
              variant,
              largestEarnAccount.customerAccountRole ===
                CustomerAccountRole.Primary
            )}
          </Typography>
          <Box className="additional-benefits">
            <Icon name={IconName.GiftOutline} />
            <Typography className="additional-benefits-text">
              {variant === "lifestyle-collection"
                ? textConstants.LC_ADDITIONAL_BENEFITS
                : textConstants.ADDITIONAL_BENEFITS}
            </Typography>
          </Box>
        </Box>
      )}
      {showFree24HourCancel && <VoidWindowNotice />}
      {selectedLodging?.lodging &&
      selectedLodging.nthNightPromotion ? (
        <HotelInformationalBanner
          content={
            <Tooltip
              title={
                <Typography className="tooltip-text">
                  {selectedLodging.nthNightPromotion?.tooltipDescription}
                </Typography>
              }
              classes={{
                popper: "nth-night-tooltip-popper",
                tooltip: "nth-night-tooltip-text",
              }}
              disableFocusListener={false}
              aria-labelledby={selectedLodging.nthNightPromotion?.tooltipDescription}
            >
              <Box className="nth-night-banner-content">
                <Typography className="nth-night-content-text">
                {selectedLodging.nthNightPromotion?.description}
                </Typography>
                <IconButton aria-labelledby="nth-night-info-label">
                  <Icon name={IconName.InfoCircle} />
                </IconButton>
              </Box>
            </Tooltip>
          }
          isMobile={false}
          isMap={false}
          iconName={IconName.Sparkle}
        />
      ) : null}
      {showOfferBanner && (
        <TravelWalletDetailsBanner
          offer={selectedLodging?.bestOfferThisLodging!}
          showButton={false}
          className="hotel-shop-offer"
          screen={SelectedTravelOfferScreen.PREMIUM_STAYS_DETAILS}
          {...props}
        />
      )}
    </Box>
  );
};
