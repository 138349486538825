import { put, putResolve, select, call } from "redux-saga/effects";
import {
  Lodging,
  ShopRequest,
  AvailabilityRequestEnum,
  AvailabilityRequest,
  PlatformEnum,
  LodgingSelection,
  LodgingSelectionEnum,
  ShopResponseEnum,
  IShopResponseBase,
  IShopResponseAvailable,
  OfferApplicabilityResponse,
  IResponse,
  IResult,
} from "redmond";
import dayjs from "dayjs";
import queryStringParser from "query-string";

import { IStoreState } from "../../../reducers/types";
import Logger from "../../../utils/logger";
import { fetchPremierCollectionShop } from "../../../api/v0/shop/fetchPremierCollectionShop";
import {
  PremierCollectionShopCallState,
  PremierCollectionShopCallError,
} from "../reducer";
import { getPremierCollectionShopSelectedAvailability } from "../reducer/selectors";
import {
  getAdultsCount,
  getChildrenCount,
  getFromDate,
  getUntilDate,
} from "../../search/reducer";
import { actions as searchActions } from "../../search/actions";
import { actions as availabilityActions } from "../../availability/actions";
import { actions } from "../actions";
import { isMobile } from "../../../utils/userAgent";
import { IPremierCollectionShopParsedQuery } from "../utils/queryStringHelpers";
import { getSelectedLodgingIndex } from "../../availability/reducer";
import { fetchPremierCollectionAvailability } from "../../../api/v0/availability/fetchPremierCollectionAvailability";
import { fetchLocations } from "../../../api/v0/search/fetchLocations";

const DEFAULT_CHECKIN_DATE_OFFSET_IN_DAYS_FROM_TODAY = 1;
const DEFAULT_CHECKOUT_DATE_OFFSET_IN_DAYS_FROM_CHECKIN = 2;

export default function* fetchPremierCollectionShopSaga(
  fetchPremierCollectionShopAction: actions.IFetchPremierCollectionShop
) {
  try {
    const { selectedAvailability }: { selectedAvailability?: Lodging } =
      yield call(
        setUpPremierCollectionShopParameters,
        fetchPremierCollectionShopAction
      );

    const shopRequestId = selectedAvailability?.price?.opaqueShopRequest;

    if (!shopRequestId) {
      throw new Error("Shop Request Id must be present.");
    }

    const requestBody: ShopRequest = {
      opaqueRequest: shopRequestId,
    };

    const response: IShopResponseBase = yield fetchPremierCollectionShop(
      requestBody
    );

    if (response.ShopResponse === ShopResponseEnum.Unavailable) {
      yield put(
        actions.setPremierCollectionShopCallStateFailed({
          premierCollectionShopCallError:
            PremierCollectionShopCallError.NoAvailability,
        })
      );
      return;
    }
    if (response.ShopResponse === ShopResponseEnum.Failure) {
      yield put(
        actions.setPremierCollectionShopCallStateFailed({
          premierCollectionShopCallError:
            PremierCollectionShopCallError.Unknown,
        })
      );
      return;
    }
    const availableResponse = response as IShopResponseAvailable;
    yield putResolve(
      actions.setPremierCollectionShopResults({
        premierCollectionShopCallState: PremierCollectionShopCallState.Success,
        payload: {
          roomInfoProducts: availableResponse.roomInfoProducts,
          trackingPropertiesV2: availableResponse.trackingPropertiesV2,
          cancellationSummary: availableResponse.cancellationSummary,
        },
      })
    );
  } catch (e) {
    Logger.debug(e);
    yield put(
      actions.setPremierCollectionShopCallStateFailed({
        premierCollectionShopCallError: PremierCollectionShopCallError.Unknown,
      })
    );
  }
}

function parseQueryString(
  fetchPremierCollectionShopAction: actions.IFetchPremierCollectionShop,
  now: dayjs.Dayjs
) {
  const queryString = fetchPremierCollectionShopAction.history.location.search;
  const parsedQueryStringPrimitive = queryStringParser.parse(queryString);

  const parsedQueryString: IPremierCollectionShopParsedQuery = {
    lodgingId: parsedQueryStringPrimitive.lodgingId as string,
    fromDate: parsedQueryStringPrimitive.fromDate as string,
    untilDate: parsedQueryStringPrimitive.untilDate as string,
    adultsCount: Number(parsedQueryStringPrimitive.adultsCount || 2),
    childrenCount: Number(parsedQueryStringPrimitive.childrenCount || 0),
    selectedLodgingIndex: Number(
      parsedQueryStringPrimitive.selectedLodgingIndex
    ),
    fromHotelAvailability: !!parsedQueryStringPrimitive.fromHotelAvailability,
  };

  try {
    parsedQueryString.lodgingSelection = parsedQueryString.fromHotelAvailability
      ? (JSON.parse(
          decodeURIComponent(
            parsedQueryStringPrimitive.lodgingSelection as string
          )
        ) as LodgingSelection)
      : (parsedQueryStringPrimitive.lodgingSelection as string);
  } catch (e) {
    Logger.debug(e);
  }

  const currentDate = dayjs().toDate();
  currentDate.setHours(0);
  currentDate.setMinutes(0);
  currentDate.setSeconds(0);
  currentDate.setMilliseconds(0);

  const parsedQueryStringFromDate: Date =
    parsedQueryString.fromDate &&
    dayjs(parsedQueryString.fromDate).toDate() >= currentDate
      ? dayjs(parsedQueryString.fromDate).toDate()
      : now
          .add(DEFAULT_CHECKIN_DATE_OFFSET_IN_DAYS_FROM_TODAY, "days")
          .toDate();
  const parsedQueryStringUntilDate: Date =
    parsedQueryString.untilDate &&
    dayjs(parsedQueryString.untilDate).toDate() > parsedQueryStringFromDate
      ? dayjs(parsedQueryString.untilDate).toDate()
      : dayjs(parsedQueryStringFromDate)
          .add(DEFAULT_CHECKOUT_DATE_OFFSET_IN_DAYS_FROM_CHECKIN, "days")
          .toDate();
  const parsedQueryStringLocationName =
    (parsedQueryStringPrimitive?.lodgingSelection as string) || "";

  return {
    parsedQueryString,
    parsedQueryStringFromDate,
    parsedQueryStringUntilDate,
    parsedQueryStringAdultsCount: parsedQueryString.adultsCount,
    parsedQueryStringChildrenCount: parsedQueryString.childrenCount,
    parsedQueryStringSelectedLodgingIndex:
      parsedQueryString.selectedLodgingIndex,
    parsedQueryStringLodgingSelection: parsedQueryString.lodgingSelection,
    parsedQueryStringIsFromHotelAvailability:
      !!parsedQueryString.fromHotelAvailability,
    parsedQueryStringLocationName,
  };
}

function* setUpPremierCollectionShopParameters(
  fetchPremierCollectionShopAction: actions.IFetchPremierCollectionShop
) {
  const state: IStoreState = yield select();

  let selectedAvailability: Lodging =
    yield getPremierCollectionShopSelectedAvailability(state);
  let fromDate: Date = yield getFromDate(state);
  let untilDate: Date = yield getUntilDate(state);
  const adultsCount: number = yield getAdultsCount(state);
  const childrenCount: number = yield getChildrenCount(state);
  const selectedLodgingIndex: number = yield getSelectedLodgingIndex(state);

  const { history } = fetchPremierCollectionShopAction;
  const now = dayjs();
  let {
    parsedQueryString,
    parsedQueryStringFromDate,
    parsedQueryStringUntilDate,
    parsedQueryStringAdultsCount,
    parsedQueryStringChildrenCount,
    parsedQueryStringSelectedLodgingIndex,
    parsedQueryStringLodgingSelection,
    parsedQueryStringIsFromHotelAvailability,
    parsedQueryStringLocationName,
  } = parseQueryString(fetchPremierCollectionShopAction, now);

  yield put(
    actions.setIsFromHotelAvailability(parsedQueryStringIsFromHotelAvailability)
  );

  if (!fetchPremierCollectionShopAction.options?.overrideStateByQueryParams) {
    if (
      selectedAvailability &&
      fromDate &&
      untilDate &&
      (parsedQueryString.lodgingId !== selectedAvailability.lodging.id ||
        parsedQueryStringFromDate !== fromDate ||
        parsedQueryStringUntilDate !== untilDate)
    ) {
      history.replace({
        ...history.location,
        search: queryStringParser.stringify({
          lodgingId: selectedAvailability.lodging.id,
          fromDate: dayjs(fromDate).format("YYYY-MM-DD"),
          untilDate: dayjs(untilDate).format("YYYY-MM-DD"),
          adultsCount,
          childrenCount,
          selectedLodgingIndex,
          lodgingSelection: encodeURIComponent(
            JSON.stringify(parsedQueryStringLodgingSelection)
          ),
        }),
      });

      ({
        parsedQueryString,
        parsedQueryStringFromDate,
        parsedQueryStringUntilDate,
        parsedQueryStringAdultsCount,
        parsedQueryStringChildrenCount,
        parsedQueryStringSelectedLodgingIndex,
      } = parseQueryString(fetchPremierCollectionShopAction, now));
    }
  }

  if (
    !selectedAvailability ||
    !fromDate ||
    !untilDate ||
    fetchPremierCollectionShopAction.options?.overrideStateByQueryParams ||
    fetchPremierCollectionShopAction.options?.forceCallHotelAvailability
  ) {
    const lodgingSelection: LodgingSelection = {
      lodgingIds: [
        parsedQueryString.lodgingId,
        "c767d0a7-5fb6-4eef-988d-3f73061dbe83",
        "68929316-b60d-46df-999b-334045e1ad72",
        "92660708-3c74-442f-a253-ddd2e299f7b6",
      ],
      preserveOrder: true,
      LodgingSelection: LodgingSelectionEnum.LodgingIds,
    };

    let availabilityRequestBody: AvailabilityRequest = {
      lodgingSelection,
      stayDates: {
        from: dayjs(parsedQueryStringFromDate).format("YYYY-MM-DD"),
        until: dayjs(parsedQueryStringUntilDate).format("YYYY-MM-DD"),
      },
      guests: {
        adults: parsedQueryStringAdultsCount,
        children:
          parsedQueryStringChildrenCount && parsedQueryStringChildrenCount > 0
            ? new Array(parsedQueryStringChildrenCount).fill(17)
            : [],
      },
      progressiveConfig: {
        pageSize: 10,
      },
      AvailabilityRequest: AvailabilityRequestEnum.InitialSearch,
      platform: isMobile() ? PlatformEnum.Mobile : PlatformEnum.Desktop,
      excludeNonLuxuryLodgings: true,
    };

    fromDate = parsedQueryStringFromDate;
    untilDate = parsedQueryStringUntilDate;

    yield putResolve(
      availabilityActions.setSelectedLodgingIndex(
        parsedQueryStringSelectedLodgingIndex != null
          ? parsedQueryStringSelectedLodgingIndex
          : selectedLodgingIndex
      )
    );
    yield putResolve(searchActions.setFromDate(fromDate));
    yield putResolve(searchActions.setUntilDate(untilDate));
    yield putResolve(
      searchActions.setOccupancyCounts({
        adults: parsedQueryStringAdultsCount,
        children:
          parsedQueryStringChildrenCount && parsedQueryStringChildrenCount > 0
            ? new Array(parsedQueryStringChildrenCount).fill(17)
            : [],
      })
    );

    yield putResolve(availabilityActions.setSearchedDates(fromDate, untilDate));
    yield putResolve(
      availabilityActions.setSearchedOccupancyCounts({
        adults: parsedQueryStringAdultsCount,
        children:
          parsedQueryStringChildrenCount && parsedQueryStringChildrenCount > 0
            ? new Array(parsedQueryStringChildrenCount).fill(17)
            : [],
      })
    );

    const availabilityResponse: {
      lodgings: Lodging[];
      offerApplicabilityResponse?: OfferApplicabilityResponse;
    } = yield call(fetchPremierCollectionAvailability, availabilityRequestBody);
    const availability = availabilityResponse.lodgings.find(
      (l) => l.lodging.id === parsedQueryString.lodgingId
    );
    if (availability) {
      selectedAvailability = availability;
    }
    yield putResolve(actions.selectLodging(selectedAvailability));
  }

  if (parsedQueryStringLocationName) {
    const { categories: locationCategories }: IResponse =
      yield fetchLocations();
    const updatedLocation = locationCategories.flatMap((category) =>
      category.results.find((result) =>
        result.label
          .toLowerCase()
          .includes(parsedQueryStringLocationName.toLowerCase())
      )
    );
    yield putResolve(searchActions.setLocation(updatedLocation[0] as IResult));
  }

  return { selectedAvailability };
}
